<template>
  <div class="track-container" ref="track" v-if="!isLoading">
    <background />
    <animation @enter-stop="changeBusyState(false)" @leave-enter="changeBusyState(true)">
      <div
        :key="viewID"
        v-if="viewID"
        class="track enter-animation-target leave-animation-target"
      >
        <node-tree :component="view.component" :nodes="view.nodes" @on-event="onEvent" />
      </div>
    </animation>
    <node-navigation />
    <resume-to-last-view></resume-to-last-view>
    <Navbar />
  </div>
  <loading v-else />
</template>

<script>
import Navbar from "../components/Navbar";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { Navbar },
  props: ["trackID"],
  data: () => ({
    busy: false,
    isLoading: true,
    loadingTrack: true,
  }),
  mounted() {
    this.onEnter();
  },
  computed: {
    ...mapGetters(["viewID", "view", "context"]),
    pageQuery: function () {
      if (!this.$route || !this.$route.query["v"]) return null;
      return this.$route.query.v;
    },
  },
  watch: {
    viewID: function (val) {
      if (this.loadingTrack) return;
      this.$router.push({ path: "/", query: { v: val } }).catch(() => {});
    },
    pageQuery: function (val) {
      if (val && val != this.viewID)
        this.dispatchTrackEvent({ type: "node-link", linkTo: val });
    },
  },
  methods: {
    ...mapActions(["fetchTrack", "dispatchTrackEvent"]),
    async onEnter() {
      try {
        this.isLoading = true;

        this.loadingTrack = true;
        await this.fetchTrack(this.trackID);
        this.loadingTrack = false;

        let entryPoint = this.context.entryPoint;
        if (this.pageQuery) entryPoint = this.pageQuery;
        this.onEvent({ type: "node-link", linkTo: entryPoint });

        this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async onEvent(event) {
      if (event.type == "node-link") window.scrollTo(0, 0);
      await this.dispatchTrackEvent(event);
    },
    changeBusyState(isBusy) {
      this.busy = isBusy;
    },
  },
};
</script>
