<template>
  <Track :trackID="'aprendizagem'" />
</template>

<script>
import Track from "./Track.vue";

export default {
  components: { Track },
  name: "Home",
};
</script>